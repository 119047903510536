<template>
  <div class="modal sign-modal">
    <div class="overlay" @click="$emit('closeSignUpModal')"></div>
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img class="close" @click="$emit('closeSignUpModal')" src="./../assets/close.svg" />
            <div class="form-fields register-content">
              <div class="form-fields-wrapper">
                <div class="title small">{{ $t("Registration") }}</div>
                <label>
                  <div class="desc">{{ $t("Date of Birth") }}</div>
                  <input ref="dob" @focus="showDatePicker" @blur="hideDatePicker" type="text"
                    :placeholder="$t('*Date of Birth')" v-model="dob" required />
                </label>
                <label>
                  <div class="desc">{{ $t(`*First name`) }}</div>
                  <input type="text" :placeholder="$t('*First name')" v-model="name" />
                </label>
                <label>
                  <div class="desc">{{ $t(`*Last name`) }}</div>
                  <input type="text" :placeholder="$t('*Last name')" v-model="surname" />
                </label>
                <label>
                  <div class="desc">{{ $t(`*Email`) }}</div>
                  <input type="email" :placeholder="$t('*Email')" v-model="email" />
                </label>
                <label v-if="countryOptionsIso && countryOptionsIso.length">
                  <div class="desc">{{ $t(`*Phone`) }}</div>
                  <VuePhoneNumberInput v-model="phone" :only-countries="countryOptionsIso"
                    :default-country-code="getDefaultCountry" :translations="{ phoneNumberLabel: $t('*Phone') }"
                    @update="phoneDataUpdate($event)" />
                </label>
                <label>
                  <div class="desc">{{ $t(`*Password`) }}</div>
                  <input type="password" :placeholder="$t('*Password')" v-model="pass" />
                </label>
                <label>
                  <div class="desc">{{ $t("Confirm password") }}</div>
                  <input type="password" :placeholder="$t('*Confirm password')" v-model="passConfirm" />
                </label>

                <label class="country-select-container">
                  <div class="desc">{{ $t("Country") }}</div>
                  <div class="select-wrapper">
                    <select v-model="country" :class="{ 'empty': !country }">
                      <option value="" disabled selected>{{ $t("Country") }}</option>
                      <option v-for="option in countryOptions" :key="option.id" :value="option.id">
                        {{ option.title }}
                      </option>
                    </select>
                    <span v-if="country" class="clear-icon" @click="clearCountry">&times;</span>
                  </div>
                </label>

                <label>
                  <div class="desc">{{ $t("City") }}</div>
                  <input type="text" :placeholder="$t('City')" v-model="city" />
                </label>

                <label>
                  <div class="desc">{{ $t("Address") }}</div>
                  <input type="text" :placeholder="$t('Address')" v-model="address" />
                </label>

                <label>
                  <div class="desc">{{ $t("Post Code") }}</div>
                  <input type="text" :placeholder="$t('Post Code')" v-model="zip" />
                </label>

                <label>
                  <div class="desc">{{ $t("Referral Code") }}</div>
                  <input type="text" v-model="referral_code" :placeholder="$t('Referral Code')" />
                </label>

                <div class="cta-container">
                  <div class="chekbox-container">
                    <label class="chekbox-label">
                      <div class="chekbox-label-wrapper">
                        <input type="checkbox" name="terms" v-model="terms" />
                        <div class="checkbox"></div>
                        <span class="title">{{ $t("I agree with") }} </span>
                        <a @click="$parent.goToPage('privacy')" class="title"> {{ $t("Privacy Policy") }}</a>
                        <span class="title"> {{ $t("and") }} </span>
                        <a @click="$parent.goToPage('terms')" class="title"> {{ $t("terms and conditions") }}</a>
                      </div>
                    </label>
                  </div>
                  <button :class="['button', { disabled: !requiredRegisterFieldsAreFilled }]" @click="submitRegister">
                    <span>{{ $t("Sign up") }}</span>
                  </button>
                </div>
                <transition name="slide">
                  <div v-if="$parent.error || error" class="error-desc desc red">
                    {{ $parent.error ? $parent.error : error }}
                  </div>
                </transition>
              </div>
              <div class="modal-bottom">
                <div class="desc">{{ $t("Have account") }}?</div>
                <div class="link switch-login" @click="openSignInModal()">
                  {{ $t("Login") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.component('vue-phone-number-input', VuePhoneNumberInput);
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "SignUpModal",
  props: [],
  components: {
    VuePhoneNumberInput
  },
  data: function () {
    return {
      email: "",
      pass: "",
      name: "",
      surname: "",
      phone: "",
      passConfirm: "",
      terms: false,
      error: null,
      dob: null,
      phoneFull: "",
      address: "",
      city: "",
      zip: "",
      country: '',
      referral_code: '',
    };
  },
  mounted() {
    this.fetchCountries();
  },
  computed: {
    ...mapGetters(['app', 'getSupportedCountryCodes']),
    ...mapGetters('country', ['countryOptions', 'countryOptionsIso']),
    getDefaultCountry() {
      return this.countryOptionsIso[0] || "";
    },
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if (this.name && this.surname && this.phone && this.email && this.pass && this.passConfirm && this.terms) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    },
  },
  methods: {
    ...mapActions('country', ['getCountries']),
    async fetchCountries() {
      try {
        await this.getCountries();
      } catch (error) {
        if (error.status === 401) {
          this.$parent.openSignInModal();
        } else {
          console.error(error);
        }
      }
    },
    clearCountry() {
      this.country = '';
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational;
    },
    closeSignUpModal() {
      this.$emit("closeSignInModal");
    },
    openSignInModal() {
      this.$emit("openSignInModal");
    },
    submitRegister() {
      if (!/^[a-zA-Z\s-]+$/.test(this.name) || !/^[a-zA-Z\s-]+$/.test(this.surname)) {
        this.$parent.error = "First name and Last name must only contain letters";
        return;
      }
      let regData = {
        dob: this.dob,
        name: this.name,
        surname: this.surname,
        email: this.email,
        phone: this.phoneFull,
        password: this.pass,
        passConfirm: this.passConfirm,
        address: this.address,
        city: this.city,
        zip: this.zip,
        country_id: this.country,
        referral_code: this.referral_code
      };
      this.$emit("registration", regData);
    },
    showDatePicker() {
      this.$refs.dob.type = 'date';
      this.$refs.dob.focus();
    },
    hideDatePicker() {
      if (!this.dob) {
        this.$refs.dob.type = 'text';
        this.dob = '';
      }
    }
  },
};
</script>
