<template>
  <main class="main product-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <transition name="fade">
            <div class="product-preview" v-if="displayItem">
              <div class="preview-container">
                <div class="preview-wrapper">
                  <div class="preview">
                    <transition name="fade">
                      <span
                        class="title added"
                        v-if="$parent.addToCartChosenItem == displayItem.id"
                        >{{ $t(`Added`) }}</span
                      >
                    </transition>
                    <img :src="getImageUrl(displayItem)" class="img" />
                  </div>
                  <div class="text">
                    <div class="desc item-type" v-if="itemType == 'pack'">
                      {{ "Pack" }}
                    </div>
                    <div class="desc item-type" v-else-if="itemType == 'item'">
                      {{ displayItem.type }}
                    </div>

                    <div class="title">
                      {{ displayItem.title || displayItem.name }}
                    </div>

                    <div
                      class="desc"
                      v-if="displayItem.hero && itemType == 'item'"
                    >
                      <span class="label">{{ $t(`Hero:`) }}</span>
                      <span :style="{ fontWeight: 700 }">
                        {{ displayItem.hero }}
                      </span>
                    </div>

                    <div
                      class="desc"
                      v-if="displayItem.quality && itemType == 'item'"
                    >
                      <span class="label">{{ $t(`Rarity:`) }}</span>
                      <span
                        :style="{
                          fontWeight: 700,
                          color: getColor(displayItem.quality),
                        }"
                      >
                        {{ displayItem.quality }}
                      </span>
                    </div>

                    <div class="desc" v-if="displayItem.description">
                      {{ displayItem.description }}
                    </div>

                    <div class="title price">
                      <span class="label">{{ $t(`Price`) }}: </span>
                      <span
                        class="old-price"
                        v-if="
                          displayItem.old_price &&
                          displayItem.old_price != '0.00'
                        "
                        >{{ displayItem.old_price }} {{ currencySymbol }}</span
                      >
                      <span class="value"
                        >{{ displayItem.price }} {{ currencySymbol }}</span
                      >
                    </div>

                    <div
                      v-if="
                        displayItem &&
                        itemType == 'item' &&
                        displayItem.last_sale &&
                        displayItem.last_sale.date &&
                        displayItem.last_sale.price
                      "
                      class="last-sale"
                    >
                      <div class="pulse-dot">
                        <div class="pulse-dot-inside"></div>
                      </div>
                      <div class="desc">
                        {{ $t(`Last sale`) }}:
                        {{ displayItem.last_sale.date | moment("MMM DD") }} for
                        {{ displayItem.last_sale.price }} {{ currencySymbol }}
                      </div>
                    </div>

                    <div
                      class="content_desc"
                      v-if="itemType == 'pack'"
                      v-html="displayItem.content_desc"
                    ></div>

                    <button
                      class="button"
                      @click="
                        addToCart(
                          displayItem,
                          displayItem.id,
                          displayItem.item_type
                        )
                      "
                    >
                      <span>{{ $t(`Add to Cart`) }}</span>
                    </button>

                    <div class="disclaimer" v-if="itemType == 'pack'">
                      <img src="@/assets/tooltip.svg" alt="" />
                      <p>
                        {{ $t(`product_disclaimer`) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ProductPage",
  props: ["addToCartChosenItem", "currency", "id", "slug"],
  data() {
    return {
      packData: null,
      itemType: "item",
      colorMap: {
        common: "#b0c3d9",
        uncommon: "#5e98d9",
        rare: "#4b69ff",
        mythical: "#8847ff",
        immortal: "#e4ae39",
        legendary: "#d32ce6",
        seasonal: "#fff34f",
        arcana: "#ade55c",
        ancient: "#eb4b4b",
      },
    };
  },
  computed: {
    ...mapGetters("app", ["currencySymbol", "currency"]),

    displayItem() {
      return this.packData || this.$parent.productPageItem;
    },
  },
  watch: {
    currency() {
      if (this.itemType === "pack") {
        this.fetchPackData();
      }
    },
  },
  mounted() {
    if (this.id) {
      this.$parent.getProductPageContent(this.id);
      this.itemType = "item";
    } else if (this.slug) {
      this.itemType = "pack";
      this.fetchPackData();
    }
  },
  methods: {
    getColor(attribute) {
      return this.colorMap[attribute.toLowerCase()] || "";
    },
    fetchPackData() {
      this.$http
        .get(`${process.env.VUE_APP_API}packs/${this.slug}`)
        .then((response) => {
          this.packData = response.data.payload;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addToCart(item, id, item_type) {
      this.$parent.addToCart(item, id, item_type);
    },
    getImageUrl(item) {
      if (this.packData) {
        return item.image_url;
      }
      return this.$parent.imgDomain + item.img_url;
    },
  },
};
</script>

<style lang="scss" scoped>
.old-price {
  color: #ff6b6b;
  font-weight: 400;
  font-size: 14px;
  position: relative;
}

.old-price:after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #ff4766;
}

.content_desc {
  margin-top: 20px;
  color: white;
}

.disclaimer {
  color: white;
  margin-top: 20px;
  font-size: 12px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  text-align: left;

  h2 {
    font-size: 14px;
  }
}

.last-sale {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;

  .desc {
    font-weight: 700;
  }

  .pulse-dot {
    width: 16px;
    height: 16px;
    padding: 4px;
    gap: 10px;
    border-radius: 50%;
    opacity: 0;
    background: #f1793e66;
    animation: pulse 2s infinite;
  }

  .pulse-dot-inside {
    width: 8px;
    height: 8px;
    gap: 0px;
    opacity: 0;
    background: #f1793e;
    animation: pulse 2s infinite;
    border-radius: 50%;
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
