var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"main product-page page-inside"},[_c('div',{staticClass:"main-wrapper"},[_c('section',{staticClass:"section hero-section product-list-section"},[_c('div',{staticClass:"wrapper"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.displayItem)?_c('div',{staticClass:"product-preview"},[_c('div',{staticClass:"preview-container"},[_c('div',{staticClass:"preview-wrapper"},[_c('div',{staticClass:"preview"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.$parent.addToCartChosenItem == _vm.displayItem.id)?_c('span',{staticClass:"title added"},[_vm._v(_vm._s(_vm.$t(`Added`)))]):_vm._e()]),_c('img',{staticClass:"img",attrs:{"src":_vm.getImageUrl(_vm.displayItem)}})],1),_c('div',{staticClass:"text"},[(_vm.itemType == 'pack')?_c('div',{staticClass:"desc item-type"},[_vm._v(" "+_vm._s("Pack")+" ")]):(_vm.itemType == 'item')?_c('div',{staticClass:"desc item-type"},[_vm._v(" "+_vm._s(_vm.displayItem.type)+" ")]):_vm._e(),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.displayItem.title || _vm.displayItem.name)+" ")]),(_vm.displayItem.hero && _vm.itemType == 'item')?_c('div',{staticClass:"desc"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t(`Hero:`)))]),_c('span',{style:({ fontWeight: 700 })},[_vm._v(" "+_vm._s(_vm.displayItem.hero)+" ")])]):_vm._e(),(_vm.displayItem.quality && _vm.itemType == 'item')?_c('div',{staticClass:"desc"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t(`Rarity:`)))]),_c('span',{style:({
                        fontWeight: 700,
                        color: _vm.getColor(_vm.displayItem.quality),
                      })},[_vm._v(" "+_vm._s(_vm.displayItem.quality)+" ")])]):_vm._e(),(_vm.displayItem.description)?_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.displayItem.description)+" ")]):_vm._e(),_c('div',{staticClass:"title price"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t(`Price`))+": ")]),(
                        _vm.displayItem.old_price &&
                        _vm.displayItem.old_price != '0.00'
                      )?_c('span',{staticClass:"old-price"},[_vm._v(_vm._s(_vm.displayItem.old_price)+" "+_vm._s(_vm.currencySymbol))]):_vm._e(),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.displayItem.price)+" "+_vm._s(_vm.currencySymbol))])]),(
                      _vm.displayItem &&
                      _vm.itemType == 'item' &&
                      _vm.displayItem.last_sale &&
                      _vm.displayItem.last_sale.date &&
                      _vm.displayItem.last_sale.price
                    )?_c('div',{staticClass:"last-sale"},[_c('div',{staticClass:"pulse-dot"},[_c('div',{staticClass:"pulse-dot-inside"})]),_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.$t(`Last sale`))+": "+_vm._s(_vm._f("moment")(_vm.displayItem.last_sale.date,"MMM DD"))+" for "+_vm._s(_vm.displayItem.last_sale.price)+" "+_vm._s(_vm.currencySymbol)+" ")])]):_vm._e(),(_vm.itemType == 'pack')?_c('div',{staticClass:"content_desc",domProps:{"innerHTML":_vm._s(_vm.displayItem.content_desc)}}):_vm._e(),_c('button',{staticClass:"button",on:{"click":function($event){return _vm.addToCart(
                        _vm.displayItem,
                        _vm.displayItem.id,
                        _vm.displayItem.item_type
                      )}}},[_c('span',[_vm._v(_vm._s(_vm.$t(`Add to Cart`)))])]),(_vm.itemType == 'pack')?_c('div',{staticClass:"disclaimer"},[_c('img',{attrs:{"src":require("@/assets/tooltip.svg"),"alt":""}}),_c('p',[_vm._v(" "+_vm._s(_vm.$t(`product_disclaimer`))+" ")])]):_vm._e()])])])]):_vm._e()])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }