import axios from "@/axios";
import i18n from "@/i18n";

const state = {
  isLoading: false,
  languages: [],
  currentLanguage: localStorage.getItem("language") || "",
  currencies: [],
  currency: localStorage.getItem("currency") || "EUR",
  currencySymbol: "",
  isAuth: false,
};

const getters = {
  currentLanguage: (state) => state.currentLanguage,
  currentLanguageName: ({ currentLanguage, languages }) => {
    if (currentLanguage) {
      const lang = languages.find((lang) => lang.id == currentLanguage);
      if (lang) {
        return lang.title;
      }
    }
    return "";
  },
  currentLanguageCode: ({ currentLanguage, languages }) => {
    if (currentLanguage) {
      const lang = languages.find((lang) => lang.id == currentLanguage);
      if (lang) {
        return lang.code;
      }
    }
    return "";
  },
  allLanguages: (state) => state.languages,
  currency: (state) => state.currency,
  currencySymbol: (state) => state.currencySymbol,
  allCurrencies: (state) => state.currencies,
  isAuthenticated: (state) => state.isAuth,
};

const actions = {
  fetchLanguages: async ({ commit, dispatch, state: { currentLanguage } }) => {
    try {
      commit("setLoading", true);
      const {
        data: { status, payload },
      } = await axios.get(process.env.VUE_APP_API + "languages");
      if (status === "OK") {
        commit("setLanguages", payload);

        if (payload.length === 0) {
          commit("setCurrentLanguage", "");
          return; // Exit early if there are no languages
        }

        // Find the default language option
        const defaultLanguage = payload.find((lang) => lang.default === 1);

        // Check if the current language is valid
        const isValidLanguage = payload.some(
          (lang) => lang.id == currentLanguage
        );

        if (!isValidLanguage) {
          // If the current language is not valid, use the default language or first available language
          const newLocaleId = defaultLanguage
            ? defaultLanguage.id
            : payload[0].id;
          await dispatch("changeLocale", newLocaleId);
        } else {
          await dispatch("changeLocale", currentLanguage);
        }
      }
    } catch (e) {
      console.log(e);
      commit("setLanguages", []);
      commit("setCurrentLanguage", "");
    } finally {
      commit("setLoading", false);
    }
  },
  changeLocale: async ({ commit, getters }, localeId) => {
    if (localeId) {
      commit("setCurrentLanguage", localeId);

      try {
        const { data } = await axios.get("/localizations", {
          params: {
            lang_id: localeId,
          },
        });

        if (data) {
          let translations = {};
          const currentLocale = getters.currentLanguageCode;

          // Get existing local translations
          const localTranslations = i18n.getLocaleMessage(currentLocale) || {};

          if (Array.isArray(data) && data.length) {
            data.forEach(
              (translation) =>
              (translations[Object.keys(translation)[0]] =
                Object.values(translation)[0])
            );
          } else if (typeof data === "object") {
            translations = data;
          }

          // Merge API translations with local translations
          const mergedTranslations = {
            ...localTranslations, // Local translations first
            ...translations        // API translations override locals
          };

          i18n.setLocaleMessage(currentLocale, mergedTranslations);
          i18n.locale = currentLocale;
        }
      } catch (e) {
        console.log(e);
        // Fallback to local translations
        i18n.setLocaleMessage(getters.currentLanguageCode, i18n.getLocaleMessage(getters.currentLanguageCode));
      }
    }
  },

  fetchCurrencies: async ({ commit, dispatch }) => {
    try {
      commit("setLoading", true);
      const { data } = await axios.get(process.env.VUE_APP_API + "currencies");
      if (data.status === "OK") {
        const currencies = data.payload.map((currency) => ({
          code: currency.code,
          symbol: currency.symbol,
          value: currency.value,
        }));
        commit("setCurrencies", currencies);
        dispatch("validateAndSetCurrency");
      }
    } catch (e) {
      console.log(e);
      const defaultCurrency = {
        code: process.env.VUE_APP_DEFAULT_CURRENCY_CODE,
        symbol: process.env.VUE_APP_DEFAULT_CURRENCY_SYMBOL,
        value: process.env.VUE_APP_DEFAULT_CURRENCY_VALUE,
      };
      commit("setCurrencies", [defaultCurrency]);
      commit("setcurrency", defaultCurrency);
    } finally {
      commit("setLoading", false);
    }
  },

  validateAndSetCurrency: ({ commit, state }) => {
    const storedCurrency = localStorage.getItem("currency");
    const validCurrency = state.currencies.find(
      (c) => c.code === storedCurrency
    );

    if (validCurrency) {
      commit("setcurrency", validCurrency);
    } else {
      const defaultCurrency =
        state.currencies.find((c) => c.code === "EUR") || state.currencies[0];
      if (defaultCurrency) {
        commit("setcurrency", defaultCurrency);
      } else {
        localStorage.removeItem("currency");
        commit("setcurrency", null);
      }
    }
  },

  changeCurrency: ({ commit, state }, currencyCode) => {
    const currency = state.currencies.find((c) => c.code === currencyCode);
    if (currency) {
      commit("setcurrency", currency);
    }
  },

  checkAuth: async ({ commit }) => {
    try {
      const { data } = await axios.get(process.env.VUE_APP_API + "is-auth");
      commit('setAuth', data.authenticated);
      return data.authenticated;
    } catch (error) {
      commit('setAuth', false);
      return false;
    }
  },
};

const mutations = {
  setLoading: (state, loading) => (state.isLoading = loading),
  setLanguages: (state, languages) => (state.languages = languages),
  setCurrentLanguage: (state, language) => {
    state.currentLanguage = language;
    if (language) {
      localStorage.setItem("language", language);
    } else {
      localStorage.removeItem("language");
    }
  },

  setCurrencies: (state, currencies) => {
    state.currencies = currencies;
  },
  setcurrency: (state, currency) => {
    if (currency) {
      state.currency = currency.code;
      state.currencySymbol = currency.symbol;
      localStorage.setItem("currency", currency.code);
    } else {
      state.currency = "";
      state.currencySymbol = "";
      localStorage.removeItem("currency");
    }
  },
  setAuth: (state, value) => {
    state.isAuth = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
