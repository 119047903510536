<template>
  <main class="main home-page">
    <img
      src="@/assets/hero_ellipse.png"
      alt=""
      class="hero-section-background-blur"
    />
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper home">
          <div class="left">
            <div class="title big">
              <p v-html="translatedText"></p>
            </div>
            <div class="desc">
              {{ $t(`c4skins offers a wide selection of Rust skins`) }}
            </div>
          </div>
          <div class="right">
            <div class="img-container">
              <img src="./../assets/weapon.png" class="img" />
            </div>
          </div>
        </div>
        <Features />
      </div>

      <ImproveSection
        :title="$t('DOTA 2 AND RUST SKINS')"
        :description="$t('One platform to shop all the skins for both of your favourite games')"
        leftImage="improve_section_left_bottom.png"
        rightImage="improve_section_right_top.png"
      />

      <section class="section last-sales-section">
        <LastSales
          :currencySymbol="currencySymbol"
          @goToProduct="goToProduct"
        />
      </section>

      <RandomCasesSlider v-if="$parent.isAuth" />

      <div class="section arrivals-section sale-section">
        <div class="wrapper">
          <div class="top">
            <div class="title">{{ $t("Most Popular") }}</div>
            <router-link to="/products/dota2" class="link">
              <span>{{ $t("View all") }}</span>
              <img src="./../assets/white-arrow.svg" class="img" />
            </router-link>
          </div>
          <SlickItem
            v-if="$parent.newArrivals.length"
            :list="$parent.newArrivals"
            :tag="''"
            :addToCartChosenItem="addToCartChosenItem"
            @addToCart="addToCart"
            @goToProduct="goToProduct"
            :currency="currencySymbol"
          />
        </div>
      </div>
      <div class="section more-offers-section wrapper">
        <img src="./../assets/more-offers.png" class="img" />
        <router-link to="/products" class="more-offers-icon">
          <img src="./../assets/more-offers-icon.svg" />
        </router-link>
        <div class="">
          <div class="left">
            <div class="title big">
              {{ $t(">1k Offers for both Rust and Dota") }}
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="wrapper more-offers-benefits">
          <div class="more-offers-card">
            <p>{{ $t("You may pick from a wide variety of skins") }}.</p>
          </div>
          <div class="more-offers-card">
            <p>
              {{
                $t(
                  "Get the latest items and skins at low prices once they are released"
                )
              }}.
            </p>
          </div>
          <div class="more-offers-card">
            <p>{{ $t("You may get exclusive, rare skins and items") }}.</p>
          </div>
        </div>
      </div>

      <ImproveSection
        :title="$t('Improve your inventory with c4skins')"
        leftImage="improve_section_left_bottom_2.png"
        rightImage="improve_section_right_top_2.png"
        customClass="second-improve-section"
      />
    </div>
  </main>
</template>
<script>
import SlickItem from "../components/SlickItem.vue";
import Features from "../components/Features.vue";
import RandomCasesSlider from "../components/RandomCasesSlider.vue";
import ImproveSection from "../components/ImproveSection.vue";
import LastSales from "../components/LastSales.vue";
export default {
  name: "Home",
  props: ["addToCartChosenItem", "currency", "currencySymbol"],
  components: {
    SlickItem,
    Features,
    ImproveSection,
    RandomCasesSlider,
    LastSales,
  },
  data: function () {
    return {
      imgDomain: "",
    };
  },
  computed: {
    translatedText() {
      return this.$t("buy_skins_text", {
        firstTitle: `<span class="cadet-blue">Rust</span>`,
        secondTitle: `<span class="orange">DOTA2</span>`,
      });
    },
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    goToProduct(item) {
      this.$emit("goToProduct", item);
    },
    addToCart(item, id, item_type) {
      this.$emit("addToCart", item, id, item_type);
    },
  },
};
</script>

<style lang="scss" scoped>
.second-improve-section {
  background: #56a7a1;
}
</style>
